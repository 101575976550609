import { useState, useCallback, createContext, useEffect, useRef } from 'react';
import ErrorModal from './ErrorModal';


export const ErrorModalContext = createContext({setErrorModal: null});

export default function ErrorModalContextProvider(props) {

    const [ errorModalState, setErrorModalState ] = useState({ showing: false, reason: undefined });
    const errorModalStateRef = useRef(errorModalState);

    useEffect(() => {
        errorModalStateRef.current = errorModalState;
    }, [errorModalState]);

    const setErrorModal = useCallback((showing, reason) => {
        if (showing) {
            setErrorModalState({showing: showing, reason: reason});
        }
        else {
            setErrorModalState({showing: showing, reason: errorModalStateRef.current.reason});
            setTimeout(() => {
                setErrorModalState({ showing: showing, reason: undefined });
            }, 200)
        }
    }, []);

    return (
        <ErrorModalContext.Provider value={{ setErrorModal: setErrorModal }}>

            <ErrorModal showing={errorModalState.showing} reason={errorModalState.reason}/>

            {props.children}

        </ErrorModalContext.Provider>
    )
}