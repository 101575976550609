import { useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ErrorModalContext } from './ErrorModalContextProvider';

import "./errorModal.scss";


export default function ErrorModal(props) {
    const errorModalContext = useContext(ErrorModalContext);
    const navigate = useNavigate();


    // Set timeout
    let timer;
    useEffect(() => {

        if (!props.reason?.restart) return;

        // eslint-disable-next-line
        timer = setTimeout(() => {
            errorModalContext.setErrorModal(false)
            navigate("/kiosk");
        }, 5 * 1000);

        return () => {
            clearTimeout(timer);
        };

    })

    return (
        <section className='modal' visible={props.showing.toString()}>
            <section className='error'>

                <h1>{props.reason?.title || "Unknown Error"}</h1>
                <h2>{props.reason?.message || "If the issue persists, please let us know."}</h2>

                <h3>{props.reason?.details || "That's all we know"}</h3>

                {   
                    // Cancel
                    (props.reason?.cancel ? 
                    <button className='cta-button white-button' onClick={() => errorModalContext.setErrorModal(false)}>DISMISS</button>
                    :

                    // Restart
                    (props.reason?.restart ?
                    <Link to="/kiosk" className='cta-button white-button' onClick={() => { errorModalContext.setErrorModal(false); clearTimeout(timer); }}>DISMISS</Link>
                    :

                    // Disabled
                    <button className='cta-button white-button disabled'>PLEASE RELOAD PAGE</button>
                    )
                )}

            </section>
        </section>
    )
}