import { useContext } from "react";
import "./genericModal.scss";
import { GenericModalContext } from "./GenericModalContextProvider";


export default function GenericModal(props) {
    const genericModalContext = useContext(GenericModalContext);

    async function clickBackground(event) {
        if (!event.target.classList.contains("modal")) return;
        if (event.target.querySelector(".login")) return;
        genericModalContext.setGenericModal(false);
    }

    return (
        <section className='modal' visible={props.showing.toString()} onClick={(event) => clickBackground(event)}>
            {props.children}
        </section>
    )
}