import { useState, useCallback, createContext, useEffect, useRef } from 'react';
import Banner from './Banner';


export const BannerContext = createContext({setBanner: null});

export default function BannerContextProvider(props) {

    const [ bannerState, setBannerState ] = useState({ showing: false, type: undefined, title: undefined, message: undefined });
    const bannerStateRef = useRef(bannerState);

    useEffect(() => {
        bannerStateRef.current = bannerState;
    }, [bannerState]);

    const setBanner = useCallback((showing, type, title, message) => {
        if (showing) {
            setBannerState({ showing: showing, type: type, title: title, message: message });
        }
        else {
            setBannerState({ showing: showing, type: bannerStateRef.current.type, title: bannerStateRef.current.title, message: bannerStateRef.current.message });
            setTimeout(() => {
                setBannerState({ showing: showing, type: undefined, title: undefined, message: undefined });
            }, 200)
        }
    }, []);

    return (
        <BannerContext.Provider value={{ setBanner: setBanner }}>

            <Banner showing={bannerState.showing} type={bannerState.type} title={bannerState.title} message={bannerState.message}/>

            {props.children}

        </BannerContext.Provider>
    )
}