import { ReactNode, useContext } from "react";
import { ModalContext } from "./ModalContextProvider";
import "./modal.scss";
import React from "react";


export default function Modal(props: { showing: boolean, allowClose: boolean, allowDrawer: boolean, children: ReactNode }) {
    const { closeModal } = useContext(ModalContext);

    async function clickBackground(event) {
        if (!event.target.classList.contains("modal-container")) return;
        if (!props.allowClose) return;
        closeModal();
    }

    return (
        <section className={`modal-container ${props.allowDrawer ? "drawerEnabled" : ""}`} visible={props.showing.toString()} onClick={(event) => clickBackground(event)}>
            {props.children}
        </section>
    )
}