import { useContext, useEffect } from 'react';
import { BannerContext } from './BannerContextProvider';

import "./banner.scss";


export default function ErrorModal(props) {
    const bannerContext = useContext(BannerContext);


    // Set timeout
    let timer;
    useEffect(() => {

        timer = setTimeout(() => {
            bannerContext.setBanner(false);
        }, 4 * 1000);

        return () => {
            clearTimeout(timer);
        };

    })

    return (
        <section className='banner' visible={props.showing.toString()} type={props.type}>

            {
                (props.type == "error" ? <i className="fa-regular fa-circle-xmark"></i> :
                <i className="fa-solid fa-circle-info"></i>)
            }

            <h1>{props.title}</h1>
            <h2>{props.message}</h2>

            <i className="fa-solid fa-xmark close-button" onClick={() => bannerContext.setBanner(false)}></i>

        </section>
    )
}