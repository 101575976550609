import { useEffect, useState } from "react";
import "./embed.scss";
import getEventHtml from "../../display/calendar/CalendarUtils";
import { useFetch } from "../../../global/fancyFetch";
import React from "react";
import * as Sentry from "@sentry/react";

export default function CalendarEmbedWrapper() {

    const [ events, setEvents ] = useState<any>();
    const fancyFetch = useFetch();

    useEffect(() => {
        getEvents();
    }, [])

    async function getEvents() {

        const id = new URLSearchParams(window.location.search).get("googleCalendarId");
        fancyFetch(
            "/api/calendar?calId="+id,
            {},
            {
                success: async (res: any) => {
                    const json = await res.json();

                    setEvents(json);

                },
                input_cal_id: (msg: string) => {
                    Sentry.captureMessage(`[CAL EMBED ID ERROR] The id (${id}) is invalid or does not exist.  The calendar embed failed to load.  Msg: ${msg}`);
                    setEvents("error");
                },
                unhandled: "auto_reload_5s"
            }
        )
    }

    if (events == "error") return (
        <div className="embed-calendar">
            <h1 style={{color: "black", fontSize: "20px", textAlign: "center"}}>Configuration Error</h1>
            <div className="tint"></div>
        </div>
    )

    else return (
        <div className="embed-calendar">
            {(events ? getEventHtml(events) : <h1 style={{color: "black", fontSize: "20px", textAlign: "center"}}>Loading...</h1>)}
            <div className="tint"></div>
        </div>
    )

}