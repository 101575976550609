import { useState, useCallback, createContext, useEffect, useRef, ReactNode } from 'react';
import Modal from './Modal';


export const ModalContext = createContext({ createModal: (allowClose: boolean, allowDrawer: boolean, content: ReactNode) => null, closeModal: null });

export default function ModalContextProvider(props) {

    const [ modalState, setModalState ] = useState({ showing: false, allowClose: undefined, allowDrawer: undefined, content: undefined });

    const createModal = useCallback((allowClose: boolean, allowDrawer: boolean, content: ReactNode) => {
        setModalState({showing: true, allowClose: allowClose, allowDrawer: allowDrawer, content: content});
    }, []);

    const closeModal = useCallback(() => {
        setModalState((s) => ({...s, showing: false}));
        setTimeout(() => {
            setModalState({showing: false, content: undefined});
        }, 200)
    }, []);

    return (
        <ModalContext.Provider value={{ createModal: createModal, closeModal: closeModal }}>

            <Modal showing={modalState.showing} allowClose={modalState.allowClose} allowDrawer={modalState.allowDrawer}>
                {modalState.content}
            </Modal>

            {props.children}

        </ModalContext.Provider>
    )
}