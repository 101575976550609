import { useState, useCallback, createContext, useEffect, useRef } from 'react';
import GenericModal from './GenericModal';


export const GenericModalContext = createContext({setGenericModal: null});

export default function GenericModalContextProvider(props) {

    const [ genericModalState, setGenericModalState ] = useState({ showing: false, callback: undefined, content: undefined });
    const genericModalStateRef = useRef(genericModalState);

    useEffect(() => {
        genericModalStateRef.current = genericModalState;
    }, [genericModalState]);

    const setGenericModal = useCallback((showing, callback, content) => {

        if (showing) {
            setGenericModalState({showing: showing, callback: callback, content: content});
        }
        else {
            setGenericModalState({showing: showing, callback: callback, content: genericModalStateRef.current.content});
            setTimeout(() => {
                setGenericModalState({showing: showing, callback: callback, content: content});
            }, 200)
        }
    }, []);

    return (
        <GenericModalContext.Provider value={{ setGenericModal: setGenericModal }}>

            <GenericModal showing={genericModalState.showing} callback={genericModalState.callback}>
                {genericModalState.content}
            </GenericModal>

            {props.children}

        </GenericModalContext.Provider>
    )
}