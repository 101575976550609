import { DateTime } from "luxon";
import { digitTailString } from "../../../global/utils.tsx";
import { getPrevMidnight, getTimeStr } from "../../../global/utils.tsx";
import siteConfig from "../../../site-config.json";

export default function getEventHtml(events) {

    const html = []

    let lastTime = 0;

    events.forEach((event) => {

        // All day
        if (event.type == "ALL_DAY") {

            if (event.day > lastTime) {
                const str = getDateDividerStr(event.day);
                html.push(
                    <div className="divider" key={str}>
                        <p>{str}</p>
                        <hr/>
                    </div>
                )
                lastTime = event.day
            }

            html.push(
                <div className="event" key={event.name + ":" + event.day}>
                    <h2 className="event all-day" config-no-wrap-line={siteConfig.calendarNoWrapLine}>{event.name}</h2>
                    <p>All Day</p>
                </div>
            )

        }

        // No Start
        else if (event.type == "STARTS_AT_BEGINNING_OF_DAY") {

            if (DateTime.fromMillis(event.end).set({ hour: 0, minute: 0, second: 0, millisecond: 0}) > lastTime) {
                const str = getDateDividerStr(DateTime.fromMillis(event.end).toMillis());
                html.push(
                    <div className="divider" key={str}>
                        <p>{str}</p>
                        <hr/>
                    </div>
                )
                lastTime = DateTime.fromMillis(event.end).set({ hour: 0, minute: 0, second: 0, millisecond: 0})
            }

            html.push(
                <div className="event" key={event.name + ":" + event.end}>
                    <h2 className="event" config-no-wrap-line={siteConfig.calendarNoWrapLine}>{event.name}</h2>
                    <p>Ends at {getTimeStr(event.end)}</p>
                </div>
            )

        }


        // No end
        else if (event.type == "GOES_TILL_END_OF_DAY") {

            if (DateTime.fromMillis(event.start).set({ hour: 0, minute: 0, second: 0, millisecond: 0}) > lastTime) {
                const str = getDateDividerStr(DateTime.fromMillis(event.start).toMillis());
                html.push(
                    <div className="divider" key={str}>
                        <p>{str}</p>
                        <hr/>
                    </div>
                )
                lastTime = DateTime.fromMillis(event.start).set({ hour: 0, minute: 0, second: 0, millisecond: 0})
            }

            html.push(
                <div className="event" key={event.name + ":" + event.start}>
                    <h2 className="event" config-no-wrap-line={siteConfig.calendarNoWrapLine}>{event.name}</h2>
                    <p>Starts at {getTimeStr(event.start)}</p>
                </div>
            )

        }

        // Normal
        else {

            if (DateTime.fromMillis(event.start).set({ hour: 0, minute: 0, second: 0, millisecond: 0}) > lastTime) {
                const str = getDateDividerStr(DateTime.fromMillis(event.start).toMillis());
                html.push(
                    <div className="divider" key={str}>
                        <p>{str}</p>
                        <hr/>
                    </div>
                )
                lastTime = DateTime.fromMillis(event.start).set({ hour: 0, minute: 0, second: 0, millisecond: 0})
            }

            html.push(
                <div className="event" key={event.name + ":" + event.start}>
                    <h2 className="event" config-no-wrap-line={siteConfig.calendarNoWrapLine}>{event.name}</h2>
                    <p>{getTimeStr(event.start)} - {getTimeStr(event.end)}</p>
                </div>
            )
            
        }

    })

    return html;

}


export function getDateDividerStr(time) {
    time = getPrevMidnight(time);

    if (time == DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0})) {
        return "TODAY"
    }

    if (time == DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0}).plus({ days: 1 })) {
        return "TOMORROW"
    }

    if (time == DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0}).plus({ days: -1 })) {
        return "YESTERDAY"
    }

    const month = new Date(time).toLocaleString('default', { month: 'short' });
    const day = new Date(time).getDate();

    return `${month} ${day}${digitTailString(day)}`

}