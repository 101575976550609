import "./notFound.scss";

export default function NotFoundPage(props: { button?: boolean }) {
    return (
        <div id="not-found-page">
            <h1>404</h1>
            <h2>The page you requested was not found.</h2>

            {props.button && <a className='cta-button-bottom' href={`/portal`}>PORTAL HOME</a>}
        </div>
    )
}